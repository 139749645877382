import styled from 'styled-components';
import { mqUntil, mqFrom } from 'styles/styles';
import Button from './button';
import Image from 'next/image';

type FrontpageBannerProps = {
  bannerItem: BoxItem;
};

export const FrontpageBanner: React.FC<FrontpageBannerProps> = ({ bannerItem }) => {
  return (
    <StyledFrontpageBanner>
      <Image
        className="banner-ultrawide"
        src={`/banner/banner-2560x470.png`}
        alt={'Blackboard Continia Learn'}
        layout="fill"
        objectFit="cover"
        objectPosition="right top"
        quality={100}
      />

      <div className="banner-card-wrapper outer-padding">
        <div className="banner-card">
          {bannerItem.subtitle && <div className="subtitle">{bannerItem.subtitle}</div>}
          {bannerItem.title && <h1>{bannerItem.title}</h1>}
          {bannerItem.description && <p>{bannerItem.description}</p>}
          {bannerItem.buttonLabel && bannerItem.buttonLink && (
            <Button
              size="large"
              mode="primary"
              linkProps={{
                href: bannerItem.buttonLink,
              }}
            >
              {bannerItem.buttonLabel}
            </Button>
          )}
        </div>
        <div className="learn-text">
          <Image
            src="/banner/learn-text-2.png"
            alt={'Learn'}
            layout="intrinsic"
            width={700}
            height={263}
            quality={100}
          />
          {/* <Image src="/banner/learn-text.png" alt={'Learn'} layout="intrinsic" width={812} height={295} /> */}
        </div>
      </div>
    </StyledFrontpageBanner>
  );
};

const StyledFrontpageBanner = styled.div`
  position: relative;
  overflow: hidden;

  .banner-card-wrapper {
    position: relative;
    z-index: 1;
    margin: 0 auto;
    padding-bottom: 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;

    ${mqFrom.desktop} {
      padding-bottom: 4rem;
      grid-template-columns: 1fr 2fr;
    }
    ${mqUntil.desktop} {
      padding: 0;
    }

    .banner-card {
      min-height: 400px;
      background-color: var(--secondary-dark);
      color: var(--primary-invert);
      width: 100%;
      padding: 3rem;
      /* flex: 1 1 0; */
      justify-self: stretch;

      .subtitle {
        line-height: 1.2;
      }

      h1 {
        margin-top: 0;
        margin-bottom: 0.5rem;
      }

      a {
        margin: 2rem 0;
      }
    }

    .learn-text {
      display: none;
      justify-content: flex-start;
      align-items: center;
      flex: 2 1 0;
      opacity: 0.8;
      margin-left: 4rem;

      > span {
        max-width: 300px !important;
      }

      ${mqFrom.desktop} {
        display: flex;
      }
      ${mqFrom.wide} {
        justify-content: center;
        padding-left: 0;
        margin-left: -10rem;

        > span {
          max-width: 400px !important;
        }
      }
      ${mqFrom.ultrawide} {
        margin-left: -1rem;

        > span {
          max-width: 420px !important;
        }
      }
    }
  }
`;

export default FrontpageBanner;
