import React from 'react';
import { GetStaticProps } from 'next';
import Image from 'next/image';
import { getFrontpage } from 'data/contentful-api';
import Layout from 'layouts/master-layout';
import styled from 'styled-components';
import ProductCard from 'components/product-card';
import { mqFrom } from 'styles/styles';
import Link from 'next/link';
import { FrontpageBanner } from 'components/frontpage-banner';
import { frontpageIds } from 'utils/constants';

type FrontpageProps = {
  frontpage: Frontpage;
};

export const Home: React.FC<FrontpageProps> = ({ frontpage }) => {
  console.log('frontpage', frontpage);
  return (
    <Layout breadcrumb={[]} title={frontpage.meta.title} description={frontpage.meta.description}>
      <StyledFrontpage className="frontpage">
        <FrontpageBanner bannerItem={frontpage.bannerItem} />
        <div className="prio-cards-wrapper outer-padding">
          <div className="prio-cards">
            {frontpage.prioItems.map((prio) => (
              <div className="prio-card" key={prio.title}>
                {prio.image && (
                  <div className="image">
                    <Image src={prio.image.url} alt={prio.image.title} layout="fill" objectFit="cover" quality={100} />
                  </div>
                )}
                <div className="details">
                  <div className="subtitle">{prio.subtitle}</div>
                  <h2>{prio.title}</h2>
                  <div className="description">{prio.description}</div>
                  <Link href={prio.buttonLink}>
                    <a>{prio.buttonLabel}</a>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="learning-path-products outer-padding" id="start">
          <h2 className="h1"> {frontpage.title}</h2>
          <div className="product-grid">
            {frontpage.products.map((product) => {
              if (!product.items || product.items.length === 0) {
                return null;
              }
              return (
                <div key={product.tag} className="learning-path-product">
                  <div>
                    {product.items.map((item) => {
                      return (
                        <ProductCard
                          key={item.meta.id}
                          item={item}
                          bookmarkData={{ learningPathId: item.meta.id }}
                        ></ProductCard>
                      );
                    })}
                    {product.feed.length > 0 && (
                      <></>
                      // <div className="youtube-list-simple">
                      //   <div className="header"></div>
                      //   <div className="info">
                      //     <div className="subtitle">{product.title} TV</div>
                      //     {product.feed.map((video) => {
                      //       return (
                      //         <article key={video.id} className="video-item">
                      //           <div className="details">
                      //             <Link href={`/tv/${product.slug}?id=${video.id}`}>
                      //               <a className="h3">{video.title}</a>
                      //             </Link>
                      //             {/* <div className="description">{video.description}</div> */}
                      //           </div>
                      //         </article>
                      //       );
                      //     })}
                      //   </div>
                      //   <div className="footer">
                      //     <Button linkProps={{ href: `/tv/${product.slug}` }} size="small" mode="transparent">
                      //       Explore all our videos at Continia Learn TV
                      //     </Button>
                      //   </div>
                      // </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </StyledFrontpage>
    </Layout>
  );
};

const StyledFrontpage = styled.div`
  margin-bottom: 3rem;

  // Prio Items
  .prio-cards-wrapper {
    margin-top: -3rem;
    position: relative;
    z-index: 1;

    .prio-cards {
      display: flex;
      gap: 1.5rem;
      flex-direction: column;
      align-items: center;

      ${mqFrom.desktop} {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
      }
    }

    .prio-card {
      background-color: var(--body-background);
      border: 1px solid var(--border-white-high-contrast);
      border-color: transparent;
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      box-shadow: 0 1.6px 3.6px 0 var(--box-shadow-medium), 0 0.3px 0.9px 0 var(--box-shadow-light);
      color: var(--text);
      display: flex;
      height: 100%;
      justify-content: flex-start;
      outline-color: var(--text);
      width: 100%;

      ${mqFrom.tablet} {
        width: 66.666%;
      }
      ${mqFrom.desktop} {
        width: 100%;
      }

      .image {
        background-color: var(--secondary-dark);
        min-width: 110px;
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        position: relative;
        img {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
        }
      }

      .details {
        border: 1px solid var(--border-white-high-contrast);
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: flex-start;
        padding: 1rem;
        width: 100%;

        h2 {
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.25;
          margin-top: 0.75rem;
          margin-bottom: 0;
        }
        .description {
          color: var(--text-subtle);
          flex-grow: 1;
          font-size: 0.875rem;
          line-height: 1.5;
          margin-top: 0.75rem;
        }
        a {
          font-size: 0.875rem;
          line-height: 1.25;
          margin-top: 0.75rem;
        }
      }
    }
  }

  // Products
  .learning-path-products {
    margin-top: 3rem;
  }
  .learning-path-product {
    padding-top: 1rem;
    padding-bottom: 1rem;

    h2 {
      text-align: center;
      margin: 1rem 0;
    }
  }

  .product-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    max-width: 1064px;

    ${mqFrom.mobile} {
      grid-template-columns: repeat(2, 1fr);
    }
    /* ${mqFrom.tablet} {
      grid-template-columns: repeat(3, 1fr);
    } */
    /* ${mqFrom.desktop} {
      grid-template-columns: repeat(4, 1fr);
    } */
  }

  // Youtube List Simple
  .youtube-list-simple {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--body-background);
    border: 1px solid var(--border-white-high-contrast);
    box-shadow: 0 1.6px 3.6px 0 var(--box-shadow-medium), 0 0.3px 0.9px 0 var(--box-shadow-light);
    color: var(--text);
    border-color: transparent;
    border: 1px solid var(--border-white-high-contrast);
    /* padding: 1rem 2rem; */
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;

    .header {
      background-color: var(--text-subtle);
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      margin-bottom: 2.2rem;
      height: 64px;
    }

    > h3 {
      margin: 0.5rem 0;
    }
    .info {
      padding: 0 1rem;
      flex: 1 1 0;
    }

    .video-item {
      color: var(--text);
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      outline-color: var(--text);
      border-left: 4px solid var(--border);
      padding-left: 0.75rem;
      margin: 16px 0;

      &:hover {
        border-left: 4px solid var(--primary-base);
      }

      .details {
        border: 1px solid var(--border-white-high-contrast);
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: flex-start;
        width: 100%;
        color: var(--text-subtle);
        flex-grow: 1;
        font-size: 0.875rem;
        line-height: 1.5;

        .h3 {
          font-size: 1rem;
          font-weight: 600;
          line-height: 1.25;
          text-align: left;
          margin: 0.3rem 0;
        }
      }
    }

    .footer {
      border-top: 1px solid var(--border);
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      padding-left: 1.5rem;
      padding-right: 0.75rem;

      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .more-videoes {
      font-size: 0.8rem;
    }
  }
`;

export const getStaticProps: GetStaticProps<FrontpageProps> = async ({ locale }) => {
  const frontpage = await getFrontpage({ locale: locale as AcceptedLocales, frontpageId: frontpageIds.frontpage });

  if (!frontpage) {
    return {
      // redirect: {
      //   destination: '/',
      //   permanent: false,
      // },
      notFound: true,
    };
  }

  return {
    props: { frontpage },
    revalidate: 60 * 60 * 24,
  };
};

export default Home;
