import { useRouter } from 'next/router';
import { useState } from 'react';
import styled from 'styled-components';
import i18n, { SettingsType } from 'utils/i18n';
import { useUserData } from 'utils/user';
import Button from './button';
import Modal from './modal';

export type BookmarkButtonProps = {
  // path: string;
  learningPathId?: string;
  moduleId?: string;
  unitId?: string;
};

export const BookmarkButton: React.FC<BookmarkButtonProps> = ({ learningPathId, moduleId, unitId }) => {
  const { locale } = useRouter();
  const { dictionary } = i18n[(locale as AcceptedLocales) || 'en-US'] as SettingsType;
  const { loggedIn, login, bookmarks, setBookmarks } = useUserData();
  const [showModal, setShowModal] = useState<'None' | 'Login' | 'Bookmark' | 'Unbookmark'>('None');
  const [loading, setLoading] = useState(false);

  const handleAddBookmark = async (): Promise<void> => {
    setLoading(true);
    const response = await fetch('/api/add-bookmark', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        mode: showModal === 'Bookmark' ? 'add' : 'remove',
        learningPathId,
        moduleId,
        unitId,
        locale,
      }),
    });
    if (response.status === 200) {
      const newBookmarks = await response.json();
      setBookmarks(newBookmarks);
    }
    setLoading(false);
    setShowModal('None');
  };

  const handleRemoveBookmark = async (bookmarkId: string): Promise<void> => {
    setLoading(true);
    const response = await fetch('/api/remove-bookmark', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        bookmarkId,
      }),
    });

    if (response.status === 200) {
      const newBookmarks = await response.json();
      setBookmarks(newBookmarks);
    }

    setLoading(false);
    setShowModal('None');
  };

  let isBookmarkedId = '';
  // Check if unit it matched
  if (unitId) {
    isBookmarkedId = bookmarks.find((b) => b.unitId === unitId)?.id;
  }
  // Check if module it matched
  else if (moduleId && !unitId) {
    isBookmarkedId = bookmarks.find((b) => b.moduleId === moduleId)?.id;
  }
  // Check if larningPath it matched
  else if (!moduleId && !unitId && learningPathId) {
    isBookmarkedId = bookmarks.find((b) => b.learningPathId === learningPathId)?.id;
  }

  // TODO:  Dictionary
  return (
    <StyledBookmarkButton>
      <Modal
        visible={showModal === 'Login'}
        headline={dictionary.bookmarkSignInToBookmark}
        onClose={() => setShowModal('None')}
      >
        <div className="buttons">
          <Button mode="primary" size="small" onClick={() => login()}>
            {dictionary.login}
          </Button>
          <Button size="small" onClick={() => setShowModal('None')}>
            {dictionary.cancel}
          </Button>
        </div>
      </Modal>
      <Modal
        visible={showModal === 'Bookmark'}
        headline={dictionary.bookmarkModalHeadline}
        onClose={() => setShowModal('None')}
      >
        <div className="buttons">
          <Button mode="primary" size="small" loading={loading} onClick={handleAddBookmark}>
            {dictionary.bookmarkSaveBtn}
          </Button>
          <Button size="small" onClick={() => setShowModal('None')}>
            {dictionary.cancel}
          </Button>
        </div>
      </Modal>
      <Modal
        visible={showModal === 'Unbookmark'}
        headline={dictionary.bookmarkRemoveModalHeadline}
        onClose={() => setShowModal('None')}
      >
        <div className="buttons">
          <Button mode="primary" size="small" loading={loading} onClick={() => handleRemoveBookmark(isBookmarkedId)}>
            {dictionary.bookmarkRemoveBtn}
          </Button>
          <Button size="small" onClick={() => setShowModal('None')}>
            {dictionary.cancel}
          </Button>
        </div>
      </Modal>
      <Button
        mode="secondary"
        iconName={isBookmarkedId ? 'circle-addition-solid' : 'circle-addition'}
        size="small"
        onClick={() => {
          if (!loggedIn) {
            setShowModal('Login');
          } else if (isBookmarkedId) {
            setShowModal('Unbookmark');
          } else {
            setShowModal('Bookmark');
          }
        }}
      >
        {isBookmarkedId ? dictionary.bookmarkSavedBtn : dictionary.bookmarkBtn}
      </Button>
    </StyledBookmarkButton>
  );
};

const StyledBookmarkButton = styled.div``;

export default BookmarkButton;
