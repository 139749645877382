import { useRouter } from 'next/router';
import { FC } from 'react';
import styled from 'styled-components';
import { formatMinutesToRead } from 'utils/helpers';
import i18n, { SettingsType } from 'utils/i18n';

export type MetaInfoProps = {
  readingTime?: number;
  type?: 'LEARNINGPATHCARD' | 'LEARNINGPATH' | 'MODULE' | 'UNIT' | 'ASSESSMENT';
  unitsCount?: number;
};

export const MetaInfo: FC<MetaInfoProps> = ({ readingTime, type, unitsCount }) => {
  const { locale } = useRouter();
  const { dictionary } = i18n[(locale as AcceptedLocales) || 'en-US'] as SettingsType;

  const dictOptions = {
    LEARNINGPATH: {
      typeName: dictionary.learningPath,
      unitsName: dictionary.modules,
    },
    MODULE: {
      typeName: dictionary.module,
      unitsName: dictionary.units,
    },
    LEARNINGPATHCARD: {
      typeName: '',
      unitsName: '',
    },
    UNIT: {
      typeName: '',
      unitsName: '',
    },
    ASSESSMENT: {
      typeName: '',
      unitsName: '',
    },
  };

  const dict = dictOptions[type];

  return (
    <StyledMetaInfo>
      {readingTime > 0 && <li className="reading-time">{formatMinutesToRead(readingTime, dictionary)}</li>}
      {dict.typeName && <li className="type">{dict.typeName}</li>}
      {dict.unitsName && unitsCount > 0 && (
        <li className="type">
          {unitsCount} {dict.unitsName}
        </li>
      )}
    </StyledMetaInfo>
  );
};

export const StyledMetaInfo = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 !important;
  padding: 0.125rem 0 0 0 !important;
  color: var(--text-subtle);
  font-size: 0.875rem;

  li {
    align-items: center;
    display: flex;
    flex-basis: auto;
    list-style: none;
  }

  li:not(:last-of-type):not(:only-of-type):after {
    content: '•';
    padding-left: 5px;
    padding-right: 5px;
  }
`;

export default MetaInfo;
