import { FC } from 'react';
import styled from 'styled-components';
import { TAG_LABELS } from 'utils/constants';

export type MetaTagsProps = {
  tags: Tag[];
};

export const MetaTags: FC<MetaTagsProps> = ({ tags }) => {
  if (!tags || !tags.length) {
    return null;
  }

  return (
    <StyledMetaTags>
      {tags.map((t) => (
        <li key={t.id}>{TAG_LABELS[t.name.toUpperCase() as keyof TagLabels] || t.name}</li>
      ))}
    </StyledMetaTags>
  );
};

export const StyledMetaTags = styled.ul`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0.5rem 0;

  li {
    margin-inline-end: 0.25rem;
    margin-inline-start: 0;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    align-items: center;
    background-color: var(--secondary-background);
    border: 1px solid var(--border-white-high-contrast);
    border-radius: 2px;
    color: var(--secondary-dark);
    display: inline-flex;
    font-weight: 400;
    height: 2em;
    justify-content: center;
    line-height: 1.5;
    padding-left: 0.5em;
    padding-right: 0.5em;
    white-space: nowrap;
    font-size: 0.75rem;
  }
`;

export default MetaTags;
