import Link from 'next/link';
import Image from 'next/image';
import styled from 'styled-components';
import MetaInfo from './meta-info';
import MetaTags from './meta-tags';
import BookmarkButton from './bookmark-button';
import { useRouter } from 'next/router';
import i18n, { SettingsType } from 'utils/i18n';

export type ProductCardProps = {
  item: LearningPath | Module;
  bookmarkData: {
    learningPathId?: string;
    moduleId?: string;
    unitId?: string;
  };
};

export const ProductCard: React.FC<ProductCardProps> = ({ item, bookmarkData }) => {
  const { locale } = useRouter();
  const { dictionary } = i18n[(locale as AcceptedLocales) || 'en-US'] as SettingsType;

  const subtitles = {
    LEARNINGPATH: dictionary.learningPath,
    MODULE: dictionary.module,
    UNIT: dictionary.unit,
  };

  return (
    <StyledProductCard>
      <div className="header">
        {item.icon ? (
          <div className="header-icon">
            <Image
              src={item.icon.url}
              width={item.icon.width}
              height={item.icon.height}
              alt={item.icon.title}
              sizes="64px"
              quality={100}
            />
          </div>
        ) : (
          <div className="empty-header-icon"></div>
        )}
      </div>
      <div className="info">
        <div className="subtitle">{subtitles[item.meta.type.toUpperCase()] || item.meta.type}</div>
        <Link href={item.meta.path}>
          <a>
            <h3>{item.title}</h3>
          </a>
        </Link>
        <MetaInfo readingTime={item.meta.readingTime} type="LEARNINGPATHCARD" />
        <MetaTags tags={item.meta.tags} />
      </div>
      <div className="footer">
        <BookmarkButton {...bookmarkData} />
      </div>
    </StyledProductCard>
  );
};

export const StyledProductCard = styled.article`
  background-color: var(--body-background);
  border: 1px solid var(--border-white-high-contrast);
  border-radius: 0.125rem;
  box-shadow: 0 1.6px 3.6px 0 var(--box-shadow-medium), 0 0.3px 0.9px 0 var(--box-shadow-light);
  color: var(--text);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
  outline-color: var(--text);
  position: relative;
  border-color: transparent;
  border: 1px solid var(--border-white-high-contrast);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  .header {
    background-color: var(--primary-base);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-bottom: 2.2rem;
  }

  h3 {
    -webkit-line-clamp: 3;
    flex-grow: 1;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25;
    text-align: left;
    margin: 0.3rem 0 1rem 0;
  }

  .empty-header-icon {
    width: 64px;
    height: 64px;
    background: #52b3c0;
    border: 3px solid #438c94;
    border-radius: 50%;
    position: relative;
    top: 32px;
    left: 1rem;
  }

  .header-icon {
    width: 64px;
    height: 64px;
    position: relative;
    top: 24px;
    left: 1rem;
  }

  .tags {
    display: flex;
    gap: 0.5rem;
    margin: 1rem 0;

    .tag {
      align-items: center;
      background-color: var(--secondary-background);
      border: 1px solid var(--border-white-high-contrast);
      border-radius: 2px;
      color: var(--econdary-dark);
      display: inline-flex;
      font-weight: 400;
      justify-content: center;
      line-height: 1.5;
      padding-left: 0.5em;
      padding-right: 0.5em;
      white-space: nowrap;
      font-size: 0.75rem;
      height: 1.666667em;
    }
  }
  .info {
    padding: 0.5rem 1rem;
    flex: 1 1 0;
  }

  .footer {
    border-top: 1px solid var(--border);
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-left: 1.5rem;
    padding-right: 0.75rem;

    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

export default ProductCard;
